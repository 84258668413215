import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import AuthContext from "../context/AuthProvider";
import { GiHamburgerMenu } from "react-icons/gi";
import Navmenu from "./Navmenu";
import { FacebookIcon, InstagramIcon, TripadvisorIcon } from "../icons/icons";


export const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
const Navbar = (props) => {
  const [openNav, setOpenNav] = useState(false);
  const { isLoginPopup, setIsLoginPopup, isRegisterPopup, setIsRegisterPopup, isMenuOpen, setIsMenuOpen } = useContext(AuthContext);





  const handleLoginPopup = () => {
    setIsLoginPopup(true);
    // setIsRegisterPopup(true);
  }
  const handleMenu = () => {

    setIsMenuOpen(!isMenuOpen);
  }

  const isAuthenticatedUser = true;


  return (
    <div>
      <div className="maxwidth flex justify-between items-center py-3 max-md:pr-5 px-5 bg-white">
        <div className="flex gap-10 justify-between max-md:flex-wrap max-h-[100px] ">
          {props.logo ? <img
            loading="lazy"
            src={props.logo}
            alt="logo"
            className="aspect-square h-[120px] w-[120px]  -mt-2.5 "
          />
            :
            <h1 className="text-[22px] md:text-[42px] font-bold" style={{ color: props.color ? props.color : "#978667" }}>
              {props.name}
            </h1>
          }

          {/* <div className="lg:flex hidden gap-10 max-md:gap-5 flex-grow justify-between my-auto text-[#333333] tracking-wide leading-6 uppercase max-md:flex-wrap">
            <Link>Home</Link>
            <Link onClick={() => scrollToSection('about')}>ABOUT US</Link>
            <Link onClick={() => scrollToSection('rooms')} >Rooms</Link>
            <Link onClick={() => scrollToSection('facilities')} >FACILITIES</Link>
            <Link onClick={() => scrollToSection('gallery')} >Gallery</Link>
            <Link onClick={() => scrollToSection('contact')} >CONTACT US</Link>
          </div> */}
        </div>

        <div className=" hidden lg:flex gap-3 items-center">
          <Link to={props.facebook} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
            <FacebookIcon color={props.color} />
          </Link>
          <Link to={props.instagram} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
            <InstagramIcon color={props.color} />
          </Link>
          {/* <Link to={props.tripadvisors} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
            <TripadvisorIcon color={props.color} />
          </Link> */}

          {isAuthenticatedUser ?
            <div className="w-[42px] md:hidden aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">

              <GiHamburgerMenu size={24} style={{ color: props.color ? props.color : "#333333" }} onClick={handleMenu} cursor={"pointer"} />
            </div>
            :
            <div className='flex gap-2'>


              <button onClick={handleLoginPopup} className="py-[7px] px-4 h-full  text-[#fff]  leading-6 uppercase  " style={{ backgroundColor: props.color ? props.color : "#978667" }}>
                Sign Up
              </button>
              <button onClick={handleLoginPopup} className="py-[7px] px-4 h-full  text-[#fff] leading-6 uppercase  " style={{ backgroundColor: props.color ? props.color : "#978667" }}>
                Login
              </button>

            </div>
          }

        </div>

        <div className="lg:hidden" onClick={() => setOpenNav(!openNav)}>
          <AiOutlineMenu size={25} style={{ color: props.color ? props.color : "#333333" }} />
        </div>
      </div>
      <div className="sm:hidden">
        <MobileNav color={props.color} openNav={openNav} setOpenNav={setOpenNav} facebook={props.facebook} instagram={props.instagram} tripadvisors={props.tripadvisors} />
      </div>


      {isMenuOpen && <Navmenu />}
    </div>
  );
};

const MobileNav = ({ color, openNav, setOpenNav, facebook, instagram, tripadvisors }) => {
  const scrollToSection = (id) => {
    setOpenNav(!openNav)
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { isLoginPopup, setIsLoginPopup } = useContext(AuthContext);




  const handleLoginPopup = () => {
    setIsLoginPopup(true);
  }





  return (
    <div
      className="flex flex-col w-[300px] max-sm:w-[80%] h-screen px-5 fixed top-0 bg-white z-10 "
      style={{
        right: openNav ? "0" : "-100%",
        transition: "all 0.3s linear",
      }}

      onBlur={() => setOpenNav(!openNav)}
    >
      <div
        className="flex justify-end mt-2 text-end text-[1.7rem] font-semibold"
        onClick={() => setOpenNav(false)}
      >
        <IoClose style={{ color: color ? color : "#333333" }} />
      </div>
      <div>
        <div className="flex flex-col gap-5 justify-between my-auto text-[#333333] tracking-wide leading-6 uppercase ">
          <Link>Home</Link>
          <Link onClick={() => scrollToSection('about')}>ABOUT US</Link>
          <Link onClick={() => scrollToSection('rooms')} >Rooms</Link>
          <Link onClick={() => scrollToSection('facilities')} >FACILITIES</Link>
          <Link onClick={() => scrollToSection('gallery')} >Gallery</Link>
          <Link onClick={() => scrollToSection('contact')} >CONTACT US</Link>
          <Link>
            <button onClick={handleLoginPopup} className="py-[7px] px-4 h-full  text-[#fff] leading-6 uppercase  " style={{ backgroundColor: color ? color : "#978667" }}>
              Login
            </button>
          </Link>
        </div>

        <div className="flex gap-5 justify-between my-auto max-md:flex-wrap max-md:max-w-full mt-4">
          <div className="flex gap-3">
            <Link onClick={() => setOpenNav(!openNav)} to={instagram} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
              <FacebookIcon color={color} />
            </Link>
            <Link onClick={() => setOpenNav(!openNav)} to={tripadvisors} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
              <InstagramIcon color={color} />
            </Link>
            {/* <Link onClick={() => setOpenNav(!openNav)} to={tripadvisors} target="_blank" className="w-[42px] aspect-square flex justify-center items-center bg-[#F6F7EB] rounded-[21px]">
              <TripadvisorIcon color={color} />
            </Link> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
