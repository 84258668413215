import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const PagePopup = ({ data, setShowModal }) => {


    const handleKeydown = useCallback(
        (event) => {
            if (event.key === "Escape") {
                setShowModal(false);
            }
        },
        [setShowModal]
    );
    useEffect(() => {
        document.addEventListener("keydown", handleKeydown);
        document.body.style.overflow = "hidden";
        return () => {
            document.removeEventListener("keydown", handleKeydown);
            document.body.style.overflow = "";
        };
    }, [handleKeydown]);


    return (
        <section
            className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-[#29422C] bg-opacity-75 transition duration-1000 ease py-5"
            onClick={() => setShowModal(false)}
        >
            <div
                className="w-full max-w-7xl h-full bg-white rounded-md px-4 py-4 max-md:p-4 overflow-hidden relative"
                onClick={(e) => e.stopPropagation()}
            >
                <div className='max-w-[73rem] mx-auto'>
                    <h1 className="text-4xl max-md:text-2xl font-bold tracking-wide capitalize text-blue-primary">
                        {data.name ? data.name : "123"}
                    </h1>
                </div>
                <div className="absolute top-0 right-0 p-1">
                    <button
                        onClick={() => setShowModal(false)}
                        className="w-8 h-8 rounded-full flex items-center hover:shadow-md justify-center shadow-lg shadow-gray-400 hover:scale-110 duration-700"
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className="w-full overflow-y-auto h-[90%] pb-4">

                    <div className="flex flex-col max-w-6xl mx-auto gap-4 mt-2">

                        <div
                            className="data flex flex-col gap-4"
                            dangerouslySetInnerHTML={{ __html: data.data ? data.data : "123" }}
                        />
                    </div>


                </div>
            </div>
        </section>
    )
}

export default PagePopup


export const CloseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 6L6 18"
                stroke="#000070"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6L18 18"
                stroke="#000070"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
