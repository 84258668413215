import React from 'react'
const Booking = () => {
    return (
        <div className='maxwidth px-5'>
            <div className='overflow-auto w-full'>
                <table className=" overflow-scroll w-full">
                    <thead >
                        <tr className="bg-[#978667] border border-[#978667]">
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Booking Id</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Guest Name</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Reservation Date</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Email-Id</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Phone</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Total Price</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Remaining Amount</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Payment Status</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Checked In</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>CheckedOut</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='border'>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                            <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>jsfkd</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Booking