import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthProvider';
import { IoClose } from "react-icons/io5";
const Register = ({ color }) => {


    const [active, setActive] = useState(false);
    const { setIsRegisterPopup } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
    });

    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value
        })

    }

    const handleSubmit = () => {
        console.log(formData)


        setFormData({
            name: "",
            email: "",
        });
    }

    const handleRegisterPopup = () => {
        setIsRegisterPopup(false);
    }

    return (

        <div className=' fixed flex justify-center items-center top-0 w-full h-screen bg-gray-900 bg-opacity-50 z-50'>

            <div className=' relative flex flex-col gap-4  max-w-[600px] md:w-[400px] mx-auto z-50 bg-[#FAF8F5] p-5'>
                <div className='absolute top-[-10px] right-[-10px]  rounded-[50%] cursor-pointer' style={{ backgroundColor: color }} onClick={handleRegisterPopup}>
                    <IoClose color="white" size={30} className='p-1' />
                </div>
                <h1 className='text-center text-[30px] font-semibold ' style={{ color: color }}>Sign Up</h1>
                <div className='flex flex-col'>
                    <label htmlFor='name'>Name</label>
                    <input type='text'
                        placeholder='Name'
                        name='name'

                        value={formData?.name}
                        onChange={handleChange}
                        className='py-2 px-4 outline-none border-2' />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='email'>Email</label>
                    <input type='email'
                        placeholder='abc@example.com'
                        name='email'

                        value={formData?.email}
                        onChange={handleChange}
                        className='py-2 px-4 outline-none border-2' />
                </div>
                <button onClick={handleSubmit} className='py-2 px-5 text-[white]' style={{ backgroundColor: color }} >Register</button>

            </div>



        </div>
    )
}

export default Register