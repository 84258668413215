import React, { useContext, useState } from 'react';
import { IoClose } from "react-icons/io5";
import AuthContext from '../context/AuthProvider';
const Login = ({ color }) => {
    const { linktohit } = useContext(AuthContext)

    const [active, setActive] = useState(false);
    const { setIsLoginPopup } = useContext(AuthContext);
    const [number, setnumber] = useState("")
    const [otp, setotp] = useState("")
    const [sentotp, setsentotp] = useState(false)

    const handleSubmit = async () => {

        try {
            const response = await fetch(`${linktohit}/feature1/signup`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "register": false,
                    "Name": "Divyanshu Sharma",
                    "emailId": "divyanshus590@gmail.com",
                    "phone": number,
                    "hotelid": localStorage.getItem("hid"),
                    "ndid": localStorage.getItem("hotelid")
                }),
            });

            const json = await response.json();
            if (json.Status) {
                console.log(json)
                setsentotp(true)
            }
            else {
                console.log(json)
            }
        }
        catch { }
    }

    const handleOtpSubmit = async () => {

        try {
            const response = await fetch(`${linktohit}/feature1/otp-verify`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "hotelid": localStorage.getItem("hid"),
                    "ndid": localStorage.getItem("hotelid"),
                    "phone": number,
                    "otp": otp
                }),
            });

            const json = await response.json();
            if (json.Status) {
                console.log(json)
                setsentotp(true)
            }
            else {
                console.log(json)
            }
        }
        catch { }
    }

    const handleCloseLoginPopup = () => {
        setIsLoginPopup(false);
    }

    return (

        <div className=' fixed flex justify-center items-center top-0 w-full h-screen bg-gray-900 bg-opacity-50 z-50'>

            <div className=' relative flex flex-col gap-4  max-w-[600px] md:w-[400px] mx-auto z-50 bg-[#FAF8F5] p-5'>
                <div className='absolute top-[-10px] right-[-10px] rounded-[50%] cursor-pointer' style={{ backgroundColor: color }} onClick={handleCloseLoginPopup}>
                    <IoClose color="white" size={30} className='p-1' />
                </div>
                <h1 className='text-center text-[30px] font-semibold' style={{ color: color }}>Login</h1>
                <div className='flex flex-col'>
                    <label htmlFor='name'>Phone Number</label>
                    <input type='text'
                        placeholder='Number'
                        name='name'
                        readOnly={sentotp ? true : false}
                        value={number}
                        onChange={(e) => { setnumber(e.target.value) }}
                        className='py-2 px-4 outline-none border-2' />
                </div>
                {sentotp ? <div className='flex flex-col'>
                    <label htmlFor='name'>Otp</label>
                    <input type='text'
                        placeholder='Otp'
                        name='name'

                        value={otp}
                        onChange={(e) => { setotp(e.target.value) }}
                        className='py-2 px-4 outline-none border-2' />
                </div> : ""}
                {!sentotp ? <button onClick={handleSubmit} className='py-2 px-5 text-[white]' style={{ backgroundColor: color }} >Login</button>
                    :
                    <button onClick={handleOtpSubmit} className='py-2 px-5  text-[white]' style={{ backgroundColor: color }} >Verify Otp</button>}

            </div>



        </div>
    )
}

export default Login