import React, { useState } from 'react'
import Logo from "../assets/Vector (8).svg"
import Facebook from "../assets/facebook.svg"
import Twitter from "../assets/twitter.svg"
import Instagram from "../assets/instagram.svg"
import Linkedin from "../assets/linkedin.svg"
import Trip from "../assets/tripadvisor.svg"
import { Link } from 'react-router-dom'

import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { footerLinks } from '../data/data.js'
import PagePopup from './PagePopup'


const Footer = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [popupData, setPopupData] = useState();
    const handleClick = (item) => {
        setShowModal(!showModal);
        setPopupData(item);
    }

    return (
        <div className='bg-[#FAF8F5] pt-10 pb-10 max-md:pt-0 max-md:pb-5 !mt-20 max-md:!mt-10 max-sm:!mt-5 '>
            <div className="maxwidth  flex flex-col px-5" >
                <div className="w-full max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-wrap max-sm:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full">
                            <div className="flex max-sm:items-center flex-col max-md:mt-10 ">
                                <div className='relative'>
                                    {props.logo ?
                                        <>
                                            <img
                                                loading="lazy"
                                                src={props.logo}
                                                alt="logo"
                                                className="aspect-square h-[160px] w-[160px]"
                                            />
                                            <div className="w-[160px] h-[160px] absolute inset-0 bg-[#FAF8F5] opacity-100 mix-blend-multiply" />
                                        </>

                                        :
                                        <h1 className="text-[42px] font-bold" style={{ color: props.color ? props.color : "#978667" }}>
                                            {props.name}
                                        </h1>
                                    }
                                    {/* <img
                                        loading="lazy"
                                        src={props.logo}
                                        className="aspect-square h-[100px] w-[100px]"
                                        alt='logo'
                                    /> */}

                                </div>
                                <div className="flex flex-grow gap-3 mt-8">
                                    <Link to={`${props.facebook}`} target="_blank">
                                        <img
                                            loading="lazy"
                                            src={Facebook}
                                            alt='instagram'
                                            className="shrink-0 aspect-square w-[42px] max-md:w-[32px] max-sm:w-[22px]"
                                        />
                                    </Link>
                                    <Link to={`${props.instagram}`} target="_blank">
                                        <img
                                            loading="lazy"
                                            src={Instagram}
                                            alt='instagram'
                                            className="shrink-0 aspect-square w-[42px] max-md:w-[32px] max-sm:w-[22px]"
                                        />
                                    </Link>
                                    {/* <Link to={`${props.tripadvisors}`} target="_blank">
                                        <img
                                            loading="lazy"
                                            src={Trip}
                                            alt=' tripadvisor'

                                            className="shrink-0 aspect-square w-[42px] max-md:w-[32px] max-sm:w-[22px]"
                                        />
                                    </Link> */}
                                    <Link to={`${props.linkedin}`} target="_blank">
                                        <img
                                            loading="lazy"
                                            src={Linkedin}
                                            alt='LinkedIn'

                                            className="shrink-0 aspect-square w-[42px] max-md:w-[32px] max-sm:w-[22px]"
                                        />
                                    </Link>
                                    <Link to={`${props.twitter}`} target="_blank">
                                        <img
                                            loading="lazy"
                                            src={Twitter}
                                            alt='twitter'

                                            className="shrink-0 aspect-square w-[42px] max-md:w-[32px] max-sm:w-[22px]"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-[50%]  max-sm:w-full">
                            <div className="flex flex-col grow text-base leading-6 max-md:mt-10 max-sm:mt-5">
                                <div className="card-heading capitalize" style={{ color: props.color ? props.color : "#978667" }}>
                                    Policies
                                </div>
                                {footerLinks.map((item, index) => (
                                    <div className="mt-6 paragraph cursor-pointer capitalize" key={index} onClick={() => handleClick(item)} >{item.name}</div>
                                ))}
                                {showModal && <PagePopup data={popupData} setShowModal={setShowModal} />}
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-[50%]  max-sm:w-full">
                            <div className="flex flex-col text-base leading-6 max-md:mt-10 max-sm:mt-5">
                                <div className="card-heading  capitalize " style={{ color: props.color ? props.color : "#978667" }}>
                                    Reach Us At
                                </div>
                                <di className="flex gap-3 mt-6 leading-6">
                                    <IoLocationOutline size={28} style={{ color: props.color ? props.color : "#978667" }} />

                                    <div className='paragraph'>{props.address}</div>
                                </di>
                                <div className="flex gap-2.5 mt-4 whitespace-nowrap">
                                    <MdOutlineMailOutline size={18} style={{ color: props.color ? props.color : "#978667" }} />
                                    <div className='paragraph'>{props.email}</div>
                                </div>
                                <div className="flex gap-3 mt-4">
                                    <FiPhoneCall size={18} style={{ color: props.color ? props.color : "#978667" }} />

                                    <div className='paragraph'>{props.phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="h-px my-8 bg-[#978667] border-0"
                    style={{ border: props.color ? `1px solid ${props.color}` : "#978667" }}
                />
                <div className="flex gap-5 max-md:gap-2 items-center self-center px-5 max-md:px-0 text-base leading-6 max-md:flex-wrap">
                    <div className='flex items-center gap-2'>
                        <img
                            alt="copyright"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/610a6346fe7d262723442cbe57fa35a8b48bd473f295fe380c8ab6e02983df06?"
                            className="shrink-0 my-auto w-3 aspect-square fill-zinc-800"
                        />
                        <div className='paragraph'>Copyright{" "}{props.name} </div>
                    </div>
                    <li className='paragraph'>All Rights Reserved</li>
                    <li className='paragraph'>Designed & Developed by <Link to="https://eazotel.com" target='_blank'>Eazotel.com</Link></li>
                </div>
            </div>
        </div>
    )
};

export default Footer;