const Terms = `<h2>1. Acceptance of Terms</h2>
<p>By accessing and using the Greek Villa Goa website (https://greekvillagoa.in/), you agree to comply with and be bound by these Terms & Conditions. If you do not agree with any part of these terms, you are prohibited from using this site. These Terms & Conditions govern your use of the website, as well as any services or products offered by Greek Villa Goa, including villa bookings and related services. Greek Villa Goa reserves the right to update or modify these terms at any time, and continued use of the site following any changes indicates your acceptance of the new terms.</p>
<h2>2. Booking and Payment Terms</h2>
<p>To confirm a reservation, an initial deposit of 30% of the total booking amount is required. The remaining balance must be paid 30 days prior to check-in. Accepted payment methods include credit card, bank transfer, and digital wallets. All payments must be made in Indian Rupees (INR) unless otherwise agreed. Failure to complete payment by the due date may result in the cancellation of your reservation without refund. For full details on payment procedures, please refer to our Payment Terms policy. Bookings are only considered confirmed upon receipt of payment and issuance of a booking confirmation email.</p>
<h2>3. Check-In, Check-Out, and Occupancy</h2>
<p>Standard check-in time is 2:00 PM, and check-out time is 11:00 AM. Early check-in and late check-out may be requested but are subject to availability and may incur additional charges. Guests must provide valid identification at check-in, as required by local regulations. The villa’s maximum occupancy, as specified on the booking confirmation, must be strictly adhered to, and any additional guests beyond the stated capacity may incur additional charges or result in cancellation of the booking without refund. Guests are expected to maintain the villa in good condition and comply with all house rules provided upon arrival.</p>
<h2>4. Cancellation, Refund, and Rescheduling Policy</h2>
<p>Cancellation requests must be made in writing and are subject to our Cancellation & Refund Policy. Cancellations made more than 60 days prior to check-in will receive a full refund, minus a processing fee. Cancellations made within 30 days of the check-in date are non-refundable. Rescheduling requests must be made at least 30 days before the check-in date and are subject to availability and an administrative fee. No refunds will be provided for no-shows or early departures. Certain promotional bookings may be non-refundable, as indicated at the time of booking.</p>
<h2>5. Use of Property and Code of Conduct</h2>
<p>Guests agree to use the property only for lawful purposes and in a manner that does not disturb the peace of the neighborhood. Parties, loud music, and disruptive behavior are strictly prohibited. Smoking is not permitted inside the villa, and failure to comply may result in additional cleaning fees. Pets are not allowed on the premises unless prior written consent has been granted. Guests must respect the property and its contents and agree to cover the cost of any damages or losses incurred during their stay. Greek Villa Goa reserves the right to terminate the stay of any guest who violates these conditions, without refund.</p>
<h2>6. Security Deposit and Damage Policy</h2>
<p>Upon check-in, a refundable security deposit of INR 10,000 is required to cover any potential damages to the property or its contents. This deposit will be returned within 7 days after check-out, provided there are no damages, missing items, or excessive cleaning requirements. Guests are responsible for reporting any damage to the property during their stay. In the event that the cost of damages exceeds the security deposit, Greek Villa Goa reserves the right to charge the guest for the excess amount. Failure to settle any outstanding charges may result in legal action.</p>
<h2>7. Liability and Indemnity</h2>
<p>Greek Villa Goa does not assume liability for personal injuries, theft, loss, or damage to personal belongings occurring on the property. Guests are advised to exercise caution and secure their belongings. By accepting these terms, guests agree to indemnify and hold Greek Villa Goa harmless from any claims, damages, or expenses arising from their use of the property. Greek Villa Goa shall not be held liable for any circumstances beyond its control, such as natural disasters, power outages, or other unforeseen events that may disrupt the guest’s stay.</p>
<h2>8. Privacy Policy and Data Protection</h2>
<p>Greek Villa Goa is committed to protecting your privacy. By using the website, you consent to the collection, use, and storage of your personal information as outlined in our Privacy Policy. This information is used solely for the purpose of managing your booking and will not be shared with third parties without your consent, except as required by law. The website employs SSL encryption to protect your personal and payment information. For more details, please refer to our Privacy Policy, available on our website.</p>
<h2>9. Governing Law and Dispute Resolution</h2>
<p>These Terms & Conditions are governed by the laws of the state of Goa, India. In the event of a dispute, the parties agree to attempt resolution through negotiation. If an agreement cannot be reached, the dispute may be referred to an independent arbitration service or brought before the courts of Goa. Guests agree that any claim related to their stay at Greek Villa Goa will be addressed within the jurisdiction of Goa, and they waive the right to pursue claims in any other jurisdiction.</p>
<h2>10. Intellectual Property Rights</h2>
<p>All content on the Greek Villa Goa website, including but not limited to text, images, logos, and designs, is the property of Greek Villa Goa and is protected by copyright and other intellectual property laws. Unauthorized use, reproduction, or distribution of any content from this website is prohibited without written consent. Greek Villa Goa reserves the right to take legal action against any party that infringes upon its intellectual property rights. Guests and website users are granted a limited license to access and use the website solely for personal use.</p>`;

const PaymentTerms = `<h2>1. Payment Schedule and Methods</h2>
<p>To confirm your booking with Greek Villa Goa, we require an initial deposit of 30% of the total booking amount. This deposit is payable within 48 hours of receiving the booking confirmation. The remaining balance of 70% must be settled no later than 30 days before the check-in date. We accept payments via bank transfer, credit card, or digital wallets such as PayPal or Google Pay. All payments must be made in Indian Rupees (INR) unless otherwise agreed upon in writing.</p>

<h2>2. Late Payments and Penalties</h2>
<p>If the full balance is not received by the due date, a late fee of 5% of the outstanding amount will be applied for each day the payment remains overdue. Should the balance not be settled within seven days of the due date, Greek Villa Goa reserves the right to cancel the reservation without refunding the deposit. This measure is to ensure fairness and to manage our booking availability effectively.</p>

<h2>3. Cancellation Policy and Refunds</h2>
<p>Guests who cancel their reservation more than 60 days prior to the check-in date will receive a full refund of the deposit, minus a processing fee of INR 2,000. For cancellations made between 30 to 59 days prior to check-in, 50% of the deposit will be refunded. No refunds will be provided for cancellations made within 30 days of the check-in date. We strongly recommend considering travel insurance to protect against unforeseen changes to your travel plans.</p>

<h2>4. Security Deposit</h2>
<p>Upon check-in, guests are required to provide a refundable security deposit of INR 10,000, which can be paid via credit card hold or cash. This deposit covers potential damages to the property, missing items, or excessive cleaning required after check-out. The security deposit will be refunded within 7 days after check-out, provided there are no deductions for the aforementioned reasons.</p>

<h2>5. Changes to Reservations and Additional Fees</h2>
<p>If you wish to amend your reservation (e.g., change of dates or number of guests), we will accommodate your request based on availability and subject to an administrative fee of INR 1,500. Any increases in rates due to changes in booking duration or additional services requested will be communicated in advance and must be settled prior to check-in. Additionally, failure to vacate the property at the agreed check-out time may result in a late check-out fee of INR 5,000 per hour.</p>

<h2>6. Payment Security and Data Protection</h2>
<p>Greek Villa Goa is committed to ensuring that your payment details are secure. We use SSL encryption to protect all transactions made on our website. Your payment details will only be used for the purpose of processing your booking and will not be shared with third parties without your explicit consent, except as required by law. By confirming a reservation, you agree to our payment terms and consent to the secure handling of your data as outlined in our Privacy Policy.</p>

<h2>7. Governing Law and Dispute Resolution</h2>
<p>These payment terms are governed by and construed in accordance with the laws of the state of Goa, India. In the event of a dispute, we encourage you to contact us directly to seek a resolution. Should a satisfactory resolution not be reached, disputes may be referred to an independent arbitration service or the courts of Goa, as deemed appropriate. Your agreement to these terms confirms your acceptance of this governing law clause.</p>`;

const cancellationRefundPolicy = `<h2>1. General Cancellation Policy</h2>

<p>At Greek Villa Goa, we understand that travel plans can change, and we strive to accommodate your needs wherever possible. All cancellations must be requested in writing via email or through our website's contact form. The date of receipt of the cancellation request will be considered the official cancellation date. Guests are eligible for a full or partial refund depending on the timing of the cancellation and the terms outlined below. We recommend reviewing our policy carefully to understand your refund eligibility and any potential fees involved.</p>
<h2>2. Cancellation Periods and Associated Refunds</h2>

For cancellations made more than 60 days prior to the scheduled check-in date, a full refund of the booking amount will be granted, minus an administrative fee of INR 2,000 to cover processing costs. If a cancellation is made between 30 and 59 days before the check-in date, 50% of the booking amount will be refunded. Cancellations within 30 days of the check-in date are non-refundable. This policy ensures that we can accommodate other guests and manage our bookings efficiently.</p>
<h2>3. Non-Refundable Bookings and Exceptions</h2>

<p>Certain promotional or discounted bookings may be classified as non-refundable, in which case the booking amount is forfeited in full upon cancellation, regardless of the notice period provided. These conditions will be clearly stated at the time of booking. Additionally, Greek Villa Goa reserves the right to make exceptions to this policy in the event of unforeseen circumstances, such as natural disasters or other emergencies, at the sole discretion of management. For such exceptions, guests may be offered a credit toward future stays instead of a cash refund.</p>
<h2>4. No-Show Policy</h2>

<p>If a guest does not arrive on the scheduled check-in date and has not provided prior notice, the entire booking amount will be forfeited. No refunds will be issued for no-shows, and Greek Villa Goa will consider the booking canceled after 24 hours of the scheduled check-in time. Should a guest wish to reschedule their stay after a no-show, a new booking must be made at the current rates, and availability cannot be guaranteed.</p>
<h2>5. Early Departure Policy</h2>

<p>Guests who choose to depart before their scheduled check-out date will not be eligible for a refund for the unused nights. This policy ensures that we can maintain room availability for future bookings. However, in cases where a guest has a compelling reason for early departure (such as a medical emergency), Greek Villa Goa may, at its discretion, offer a credit for future stays. The issuance of such credits is not guaranteed and will be evaluated on a case-by-case basis.</p>
<h2>6. Processing of Refunds</h2>

<p>Refunds will be processed within 14 business days of receiving a written cancellation request. Refunds are issued via the original payment method used at the time of booking. If the booking was paid via credit card, please allow additional time for the refund to reflect on your statement, depending on your bank's processing times. For bookings paid via bank transfer, Greek Villa Goa will request the necessary details to complete the refund. Refunds are issued in Indian Rupees (INR), and any bank fees or currency exchange differences are the responsibility of the guest.</p>
<h2>7. Modification of Cancellation Policy</h2>

<p>Greek Villa Goa reserves the right to modify this Cancellation & Refund Policy at any time. Guests will be informed of any changes to the policy at the time of booking or via email if changes occur after a booking has been made. The cancellation policy in effect at the time of booking will apply to your reservation. By confirming a reservation, you acknowledge that you have read, understood, and agreed to the terms outlined in this policy.</p>`;

const cancelBooking = `<h2>1. Initiating a Cancellation Request</h2>
<p>If you need to cancel your booking at Greek Villa Goa, you may do so by submitting a written request via email or through the contact form on our website. In your cancellation request, please include your booking reference number, full name, check-in and check-out dates, and the reason for cancellation. Cancellations will only be considered valid once we have received and acknowledged your written request. This process ensures that your cancellation is properly documented and that all necessary details are confirmed.</p>
<h2>2. Cancellation Confirmation and Processing</h2>
<p>Upon receipt of your cancellation request, we will review it and send you a confirmation email outlining any applicable fees or refunds, as per our Cancellation & Refund Policy. This email will serve as official documentation of your cancellation. It is important to note that the cancellation is not finalized until you receive this confirmation. We recommend keeping a copy of this confirmation for your records. The processing time for cancellations typically ranges from 3 to 5 business days, during which we will evaluate your eligibility for a refund based on our policy.</p>
<h2>3. Cancellation Fees and Refunds</h2>
<p>Greek Villa Goa’s cancellation policy includes specific fees that may apply based on the timing of your cancellation. For cancellations made more than 60 days before your check-in date, a processing fee of INR 2,000 will be deducted from your refund. Cancellations made between 30 and 59 days prior to check-in are eligible for a 50% refund of the total booking amount. Cancellations within 30 days of the check-in date are non-refundable. By submitting a cancellation request, you acknowledge and agree to these terms and any associated fees that may be applied.</p>
<h2>4. Rescheduling Instead of Cancelling</h2>
<p>If your travel plans have changed, but you wish to retain your reservation, we encourage you to consider rescheduling instead of canceling. Rescheduling is subject to availability and must be requested at least 30 days before the original check-in date. While there may be an administrative fee for rescheduling, it may be a more cost-effective option than canceling your booking entirely. Any difference in rates due to a change in dates will be communicated to you, and the new booking will be confirmed only once these payments have been made.</p>
<h2>5. Non-Refundable Booking Cancellation</h2>
<p>In cases where a booking is marked as non-refundable, any cancellation will result in a forfeiture of the full booking amount. Non-refundable bookings typically apply to special promotions or discounts. Guests are encouraged to thoroughly review the terms of their booking at the time of reservation. These conditions will be clearly indicated during the booking process. Greek Villa Goa will not issue refunds for cancellations on non-refundable bookings, except in cases of unforeseen emergencies, at the discretion of management.</p>
<h2>6. No-Show Cancellations</h2>
<p>If you fail to arrive on the scheduled check-in date and do not provide prior notice, your booking will be considered a “no-show.” In such cases, the entire booking amount will be forfeited, and Greek Villa Goa reserves the right to cancel the reservation without a refund. If you anticipate being delayed, please inform us as soon as possible so we can make accommodations. However, we cannot guarantee any adjustments to your reservation for late arrivals, as these are subject to availability.</p>
<h2>7. Alternative Travel Arrangements</h2>
<p>If you cancel your booking and are unable to reschedule, we recommend exploring other accommodation options in the area or considering a future stay with Greek Villa Goa. While we may not be able to provide a refund for late cancellations, our team is always available to assist you with travel advice and suggestions. We understand that travel plans can be unpredictable, and we strive to offer as much support as possible during these situations.</p>
<h2>8. Future Bookings and Credits</h2>
<p>In cases of cancellations due to unavoidable circumstances (such as medical emergencies or travel restrictions), Greek Villa Goa may offer a credit towards a future stay at our discretion. These credits are typically valid for a period of one year from the original booking date and can be applied to any available accommodation. Please note that credits are not guaranteed and are issued on a case-by-case basis. Contact our reservations team to inquire about the availability of credits and to make future arrangements.</p>
<h2>9. Contact Information and Support</h2>
<p>For assistance with cancellations or any inquiries regarding your booking, please contact our customer support team. Our contact information is available on our website, and we are here to assist you Monday through Friday, from 9:00 AM to 6:00 PM IST. By providing us with all necessary details at the time of cancellation, we can help expedite the process and ensure that your request is handled efficiently.</p>
<h2>10. Amendments to Cancellation Policy</h2>
<p>Greek Villa Goa reserves the right to amend this cancellation policy at any time. Any changes to the policy will be communicated to guests at the time of booking or via email. The policy in effect at the time of booking will govern your reservation, and you will be held to those terms. We encourage you to review our cancellation policy periodically to stay informed about any updates.</p>`;

export const footerLinks = [
  {
    name: "terms & conditions",
    data: Terms,
  },
  {
    name: "payment terms",
    data: PaymentTerms,
  },
  {
    name: "cancellation & refund policy",
    data: cancellationRefundPolicy,
  },
  {
    name: "cancel booking",
    data: cancelBooking,
  },
];
