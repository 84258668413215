import React from 'react'
import { FaUserLarge } from "react-icons/fa6";
const Profile = () => {
    return (

        <div className='max-w-[600px] mx-auto'>
            <div className='flex flex-col justify-center items-center'>
                <div className='h-[150px] w-[150px] rounded-[50%] flex justify-center items-center overflow-hidden bg-[#ececec]'>
                    <FaUserLarge color='white' size={100} />
                </div>
                <div className='mt-3'>9645789243</div>

            </div>
            <div className='mt-5 flex flex-col gap-5'>
                <input className='outline-none  border-b-2 border-[#ececec]' placeholder='First Name' type='text' value={""} name='firstname' />
                <input className='outline-none  border-b-2 border-[#ececec]' placeholder='Last Name' type='text' value={""} name='lastname' />
                <input className='outline-none  border-b-2 border-[#ececec]' placeholder='Email' type='email' value={""} name='email' />
                <div className='flex justify-center gap-5 items-center '>
                    <button className='border-none bg-[#978667] w-[150px] p-2 text-white'>SAVE</button>
                    <button className='border-none bg-[#978667] w-[150px] p-2 text-white'>RESET</button>
                </div>
            </div>
        </div>
    )
}

export default Profile